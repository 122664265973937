import { render, staticRenderFns } from "./AccountManagement.vue?vue&type=template&id=2ca86b47"
import script from "./AccountManagement.vue?vue&type=script&lang=js"
export * from "./AccountManagement.vue?vue&type=script&lang=js"
import style0 from "./AccountManagement.vue?vue&type=style&index=0&id=2ca86b47&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/crm_web_5uHk/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ca86b47')) {
      api.createRecord('2ca86b47', component.options)
    } else {
      api.reload('2ca86b47', component.options)
    }
    module.hot.accept("./AccountManagement.vue?vue&type=template&id=2ca86b47", function () {
      api.rerender('2ca86b47', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/BasicOperation/extensionManagement/AccountManagement.vue"
export default component.exports