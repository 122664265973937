import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getProjectList, ExtensionAaccount, ExtensionAaccountAdd, ExtensionAaccountUpdateBalance, ExtensionAaccountRead, ExtensionAaccountExit, ExtensionAaccountInfolog, ExtensionAaccountEditrebate, ExtensionAaccountDelete } from '@/api/ceshi_xiugai/popularize';
import { Message } from 'element-ui';
import { getPlatform as _getPlatform } from '@/api/ceshi_xiugai/popularize';
import Head from '@/components/head/index';
import TimeComponent from '@/components/TimeComponent/index';
export default {
  name: 'extensionProject',
  data: function data() {
    var _ref;
    return _ref = {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      DelegaShow: true,
      dialogProject: false,
      ChangeDetails: false,
      dialogProjectSe: false,
      RebateModification: false,
      ToUpdateLoUpdate: false,
      //系统教程
      title: '推广账户管理',
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0,
        //总条数，不是总页数
        page1: 1,
        pagesize1: 10,
        total1: 0 //总条数，不是总页数
      },
      idS: '',
      num: '',
      eoa_id: '',
      rebatres: {
        rebateS: ''
      },
      RebateId: '',
      klsjId: '',
      JumpAddress: '',
      bleds: false,
      promotionShow: true,
      listLoading: false,
      enterTimeShow: false
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "dialogProject", false), "tableData", []), "PromotionId", []), "enterTime", ''), "finalFollow", ''), "advert_id", []), "platformOptions", []), "account_abbreviation", ''), "account_name", ''), "sdata_time", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "edata_time", ''), "extension", {
      account: '',
      //账户简称
      radio: 1,
      //运营方式
      rebate: '',
      //返点
      platform: '',
      //推广平台
      promotion_account: '',
      //推广账户名
      organizer_account_id: '',
      //组织者账户id
      promotion_password: '',
      //账户密码
      promotion_token: '',
      //账户token
      promotion_ID: '' //账户ID
    }), "platformArr", []), "rules", {
      account: [{
        required: true,
        message: '请输入账户简称',
        trigger: 'blur'
      }],
      rebate: [{
        required: true,
        message: '请输入返点',
        trigger: 'blur'
      }],
      platform: [{
        required: true,
        message: '请选择推广平台',
        trigger: 'change'
      }],
      promotion_account: [{
        required: true,
        message: '请输入推广账户名',
        trigger: 'blur'
      }],
      organizer_account_id: [{
        required: true,
        message: '请输入组织者账户id',
        trigger: 'blur'
      }],
      promotion_password: [{
        required: true,
        message: '请输入推广账户密码',
        trigger: 'blur'
      }],
      promotion_token: [{
        required: true,
        message: '请输入推广账户token',
        trigger: 'blur'
      }],
      promotion_ID: [{
        required: true,
        message: '请输入推广账户ID',
        trigger: 'blur'
      }]
    }), "DetailsData", []);
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this2.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
    } else {}
  },
  components: {
    Head: Head,
    TimeComponent: TimeComponent
  },
  methods: {
    getPlatform: function getPlatform() {
      var _this3 = this;
      _getPlatform({
        type: 0
      }).then(function (respomse) {
        _this3.platformOptions = respomse.data;
        _this3.platformArr = respomse.data;
      });
    },
    dialogFork: function dialogFork() {
      this.ChangeDetails = false;
      this.DetailsData = [];
      this.query.page1 = 1;
      this.query.total1 = 0;
      this.query.pagesize1 = 10;
    },
    selectRows: function selectRows(val) {
      var dataId = [];
      for (var i = 0; i < val.length; i++) {
        dataId.push(val[i].id);
      }
      this.PromotionId = _toConsumableArray(new Set(dataId));
    },
    getList: function getList(page, pagesize) {
      var _this4 = this;
      this.listLoading = true;
      ExtensionAaccount({
        page: String(page),
        pagesize: String(pagesize),
        account_abbreviation: this.account_abbreviation,
        account_title: this.account_name,
        popularize_platform_id: this.advert_id.toString(),
        sdata_update_time: this.sdata_time,
        edata_update_time: this.edata_time
      }).then(function (respomse) {
        _this4.listLoading = false;
        _this4.tableData = respomse.data.data;
        _this4.query.total = respomse.data.total;
      }).catch(function () {
        _this4.tableData = [];
        _this4.listLoading = false;
      });
    },
    advertIdChange: function advertIdChange(val) {},
    NewPromotion: function NewPromotion(num, id) {
      var _this5 = this;
      this.num = num;
      this.idS = id;
      this.dialogProject = true;
      if (num == 2) {
        ExtensionAaccountRead({
          id: id
        }).then(function (res) {
          _this5.getPlatform();
          _this5.extension.account = res.data.account_abbreviation; //账户简称
          _this5.extension.radio = res.data.operate_type; //运营方式
          _this5.extension.rebate = res.data.rebate.toString(); //返点
          _this5.extension.promotion_ID = res.data.account;
          if (res.data.popularize_platform_id == '71') {
            _this5.extension.platform = res.data.popularize_platform_id; //推广平台
            _this5.extension.promotion_account = res.data.account_title; //推广账户名
            _this5.extension.organizer_account_id = res.data.account_name; //组织者账户id
            _this5.promotionShow = true;
          } else {
            _this5.promotionShow = false;
            _this5.extension.promotion_account = res.data.account_title; //推广账户名
            _this5.extension.platform = res.data.popularize_platform_id; //推广平台
            _this5.extension.promotion_password = res.data.account_password; //账户密码
            _this5.extension.promotion_token = res.data.access_token; //账户token
          }
        });
      }
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '最后修改时间') {
        this.enterTimeShow = true;
      }
    },
    FollowClick: function FollowClick(start, end, title) {
      if (title === '最后修改时间') {
        this.sdata_time = start;
        this.edata_time = end;
      }
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    forkClick: function forkClick(item) {
      this.query.page = 1;
      if (item === '最后修改时间') {
        this.enterTimeShow = false;
        this.sdata_time = '';
        this.edata_time = '';
        this.finalFollow = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    cancel: function cancel(formName) {
      this.$refs[formName].resetFields();
    },
    submitEnterTime: function submitEnterTime(item) {},
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    handleSizeChangeA: function handleSizeChangeA(val) {
      //每页 ${val} 条
      this.query.pagesize1 = val;
      this.detailsClick(this.klsjId);
    },
    handleCurrentChangeA: function handleCurrentChangeA(val) {
      //当前页
      this.query.page1 = val;
      this.detailsClick(this.klsjId);
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    queryClick: function queryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    limitInput: function limitInput(value, name) {
      if (value > 100) {
        this.extension.rebate = '';
        this.$message({
          message: '禁止返点大于100',
          type: 'warning'
        });
      } else {
        this.extension[name] = ('' + value // 第一步：转成字符串
        ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
        .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
        .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
        .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      }
    },
    rebateInput: function rebateInput(val) {
      if (val > 100) {
        this.extension.rebate = '';
        this.$message({
          message: '禁止返点大于100',
          type: 'warning'
        });
      }
    },
    rebateInputA: function rebateInputA(value, name) {
      if (value > 100) {
        this.rebatres.rebateS = '';
        this.$message({
          message: '禁止返点大于100',
          type: 'warning'
        });
      } else {
        this.rebatres[name] = ('' + value // 第一步：转成字符串
        ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
        .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
        .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
        .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      }
    },
    rebateInputDelate: function rebateInputDelate() {
      this.RebateModification = false;
      this.rebatres.rebateS = '';
    },
    platformClick: function platformClick(val) {
      // let labels = this.$refs["cascader"].getCheckedNodes()[0].label
      var id = val.toString();
      if (id == '71') {
        this.promotionShow = true;
      } else if (id == '80') {
        this.promotionShow = false;
      }
      this.extension.organizer_account_id = '';
      this.extension.promotion_account = '';
      this.extension.promotion_password = '';
      this.extension.promotion_token = '';
      this.extension.promotion_ID = '';
    },
    NewPromotionClick: function NewPromotionClick(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.formChange();
        } else {
          _this6.$message({
            message: '请完善信息！',
            type: 'warning',
            duration: 2000
          });
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.dialogProject = false;
      this.$refs[formName].resetFields();
    },
    // form表单提交
    formChange: function formChange() {
      var _this7 = this;
      var _this = this.extension;
      this.query.page = 1;
      this.bleds = true;
      if (this.num == 1) {
        ExtensionAaccountAdd({
          popularize_platform_id: _this.platform.toString(),
          account_title: _this.promotion_account,
          account_name: _this.organizer_account_id,
          account_abbreviation: _this.account,
          operate_type: _this.radio,
          account_password: _this.promotion_password,
          access_token: _this.promotion_token,
          rebate: _this.rebate,
          account: _this.promotion_ID
        }).then(function (res) {
          if (res.code == 0) {
            _this7.bleds = false;
            if (res.data.status == 0) {
              _this7.dialogProjectSe = true;
              _this7.DelegaShow = false;
              _this7.JumpAddress = res.data.oauth_link;
              _this7.eoa_id = res.data.eoa_id;
            } else {
              _this7.DelegaShow = true;
              _this7.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success'
              });
              _this7.dialogProject = false;
              _this7.dialogProjectSe = false;
              _this7.getList(_this7.query.page, _this7.query.pagesize);
              _this7.empty();
            }
          } else {
            _this7.$message.error(res.msg);
          }
        });
      } else {
        this.$confirm('编辑账户后会覆盖账户全部的更新记录，是否确认？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          ExtensionAaccountExit({
            id: _this7.idS,
            popularize_platform_id: _this.platform.toString(),
            account_title: _this.promotion_account,
            account_name: _this.organizer_account_id,
            account_abbreviation: _this.account,
            operate_type: _this.radio,
            account_password: _this.promotion_password,
            access_token: _this.promotion_token,
            rebate: _this.rebate,
            account: _this.promotion_ID
          }).then(function (res) {
            if (res.code == 0) {
              _this7.bleds = false;
              if (res.data.status == 0) {
                _this7.dialogProjectSe = true;
                _this7.DelegaShow = false;
                _this7.JumpAddress = res.data.oauth_link;
                _this7.eoa_id = res.data.eoa_id;
              } else {
                _this7.DelegaShow = true;
                _this7.$notify({
                  title: '成功',
                  message: '编辑成功',
                  type: 'success'
                });
                _this7.dialogProject = false;
                _this7.dialogProjectSe = false;
                _this7.getList(_this7.query.page, _this7.query.pagesize);
                _this7.empty();
              }
            } else {
              _this7.$message.error(res.msg);
            }
          });
        }).catch(function () {});
      }
    },
    Delegating: function Delegating() {
      this.DelegaShow = true;
      window.open(this.JumpAddress, '_blank');
    },
    DelegatingComplete: function DelegatingComplete() {
      this.formChange();
      // checkauthorize({
      //     eoa_id: this.eoa_id
      // }).then((res) => {
      //     if (res.data.status == 0) {
      //         this.DelegaShow = false;
      //     } else {
      //         this.$notify({
      //             title: '成功',
      //             message: '授权成功',
      //             type: 'success'
      //         });
      //     }
      // });
    },
    AccountDetails: function AccountDetails(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        } else if (index === 2 || index === 4) {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });
          if (!values.every(function (value) {
            return isNaN(value);
          })) {
            sums[index] = values.reduce(function (prev, curr) {
              var value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
          } else {
            sums[index] = '--';
          }
        }
      });
      return sums;
    },
    detailsClick: function detailsClick(id) {
      var _this8 = this;
      this.klsjId = id;
      this.ChangeDetails = true;
      ExtensionAaccountInfolog({
        id: id,
        page: this.query.page1,
        pagesize: this.query.pagesize1
      }).then(function (res) {
        _this8.query.total1 = res.data.total;
        _this8.DetailsData = res.data.data;
      });
    },
    ChangeRebate: function ChangeRebate(id) {
      this.RebateId = id;
      this.RebateModification = true;
    },
    modifyClick: function modifyClick() {
      var _this9 = this;
      if (this.rebatres.rebateS != '') {
        ExtensionAaccountEditrebate({
          id: this.RebateId,
          rebate: this.rebatres.rebateS
        }).then(function (res) {
          _this9.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          _this9.rebatres.rebateS = '';
          _this9.RebateModification = false;
          _this9.detailsClick(_this9.klsjId);
        });
      } else {
        this.$message({
          message: '请输入返点',
          type: 'warning',
          duration: 2000
        });
      }
    },
    DeletePromotion: function DeletePromotion(id) {
      var _this10 = this;
      this.$confirm('确认是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        ExtensionAaccountDelete({
          id: id
        }).then(function (res) {
          _this10.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          _this10.getList(_this10.query.page, _this10.query.pagesize);
        });
      }).catch(function () {});
    },
    empty: function empty() {
      this.extension = {
        account: '',
        //账户简称
        radio: 1,
        //运营方式
        rebate: '',
        //返点
        platform: '',
        //推广平台
        promotion_account: '',
        //推广账户名
        organizer_account_id: '',
        //组织者账户id
        promotion_password: '',
        //账户密码
        promotion_token: '',
        //账户token
        promotion_ID: '' //账户ID
      };
    },
    ToUpdate: function ToUpdate() {
      var _this11 = this;
      if (this.PromotionId.length <= 0) {
        this.$message({
          type: 'warning',
          message: '请选择推广账号'
        });
      } else {
        this.ToUpdateLoUpdate = true;
        this.listLoading = true;
        ExtensionAaccountUpdateBalance({
          era_ids: this.PromotionId.toString()
        }).then(function (res) {
          _this11.query.page = 1;
          _this11.ToUpdateLoUpdate = false;
          _this11.getList(_this11.query.page, _this11.query.pagesize);
        });
      }
    }
  }
};